// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceptable-use-policy-js": () => import("../src/pages/acceptable-use-policy.js" /* webpackChunkName: "component---src-pages-acceptable-use-policy-js" */),
  "component---src-pages-brand-guidelines-js": () => import("../src/pages/brand-guidelines.js" /* webpackChunkName: "component---src-pages-brand-guidelines-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-copyright-policy-js": () => import("../src/pages/copyright-policy.js" /* webpackChunkName: "component---src-pages-copyright-policy-js" */),
  "component---src-pages-corporate-colors-js": () => import("../src/pages/corporate-colors.js" /* webpackChunkName: "component---src-pages-corporate-colors-js" */),
  "component---src-pages-credits-js": () => import("../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-data-processing-addendum-js": () => import("../src/pages/data-processing-addendum.js" /* webpackChunkName: "component---src-pages-data-processing-addendum-js" */),
  "component---src-pages-developer-terms-of-use-js": () => import("../src/pages/developer-terms-of-use.js" /* webpackChunkName: "component---src-pages-developer-terms-of-use-js" */),
  "component---src-pages-how-it-works-js": () => import("../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logo-guidelines-js": () => import("../src/pages/logo-guidelines.js" /* webpackChunkName: "component---src-pages-logo-guidelines-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-measures-js": () => import("../src/pages/security-measures.js" /* webpackChunkName: "component---src-pages-security-measures-js" */),
  "component---src-pages-terms-of-service-js": () => import("../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

