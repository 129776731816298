module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AirPage","short_name":"AirPage","start_url":"/","background_color":"#ffffff","theme_color":"#01A1FF","display":"standalone","icon":"src/assets/favicon/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://airpage.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-86170279-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0},
    }]
